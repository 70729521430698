export const useNutrientStore = defineStore('nutrient', () => {
  const $api = useApi();

  const allNutrients = ref([]);
  const activeNutrient = ref();
  const totalNutrient = ref(0);
  const totalPages = ref(1);

  async function fetchAllNutrients(data?: Object = {}) {
    const nutrients = await $api.post(`/nutrient/all`, data);
    if (nutrients.data) {
      allNutrients.value = nutrients.data;
      totalNutrient.value = nutrients.count;
      totalPages.value = nutrients.totalPages;
    }
  }

  async function fetchDetailedNutrient(nutrientId: string) {
    const { data: nutrient } = await $api.get(`/nutrient/${nutrientId}`);
    activeNutrient.value = nutrient;
  }

  async function updateNutrient(
    nutrientId: string,
    data: Record<string, string>,
  ) {
    const { data: nutrientUpdated } = await $api.put(
      `/nutrient/${nutrientId}`,
      data,
    );
    if (nutrientUpdated) {
      const nutrientToUpdateIndex = allNutrients.value.findIndex(
        (n) => n.id === nutrientId,
      );
      allNutrients.value[nutrientToUpdateIndex] = {
        ...allNutrients.value[nutrientToUpdateIndex],
        ...data,
      };
    }
  }

  return {
    allNutrients,
    activeNutrient,
    totalNutrient,
    totalPages,
    fetchAllNutrients,
    fetchDetailedNutrient,
    updateNutrient,
  };
});
